export default {
  GAME_FOOD: 'GAME_FOOD'
  , GAME_LAST_TURN: 'GAME_LAST_TURN'
  , GAME_WRONG_PHASE: 'GAME_WRONG_PHASE'
  , COOLDOWN: 'COOLDOWN'

  , ANIMAL_DONT_WANT_FOOD: 'ANIMAL_DONT_WANT_FOOD'
  , ANIMAL_DONT_NEED_FOOD: 'ANIMAL_DONT_NEED_FOOD'
  , ANIMAL_HAS_NO_FOOD: 'ANIMAL_HAS_NO_FOOD'
  , ANIMAL_BLOCKED_FROM_FOOD: 'ANIMAL_BLOCKED_FROM_FOOD'
  , ANIMAL_IN_COVER: 'ANIMAL_IN_COVER'

  , TRAIT_MULTIPLE: 'TRAIT_MULTIPLE'
  , TRAIT_REGENERATION_TRAIT_MAX: 'TRAIT_REGENERATION_TRAIT_MAX'
  , TRAIT_REGENERATION_DEAD: 'TRAIT_REGENERATION_DEAD'
  , PLANT_FOOD: 'PLANT_FOOD'
  , PLANT_FOOD_NO_ACCESS: 'PLANT_FOOD_NO_ACCESS'
  , PLANT_FOOD_FRUIT: 'PLANT_FOOD_FRUIT'

  , PLANT_COVERS_ZERO: 'PLANT_COVERS_ZERO'

  , TRAIT_TYPE_DENIED: 'TRAIT_TYPE_DENIED'

  , TRAIT_ATTACK_UNAVOIDABLE: 'TRAIT_ATTACK_UNAVOIDABLE'
  , TRAIT_ATTACK_TOO_MUCH_DEFENSES: 'TRAIT_ATTACK_TOO_MUCH_DEFENSES'

  , TRAIT_PLACEMENT_MULTIPLE: 'TRAIT_PLACEMENT_MULTIPLE'
  , TRAIT_PLACEMENT_HIDDEN: 'TRAIT_PLACEMENT_HIDDEN'
  , TRAIT_PLACEMENT_CTT_SELF: 'TRAIT_PLACEMENT_CTT_SELF'
  , TRAIT_PLACEMENT_CTT_ENEMY: 'TRAIT_PLACEMENT_CTT_ENEMY'
  , TRAIT_PLACEMENT_CTT_ANIMAL: 'TRAIT_PLACEMENT_CTT_ANIMAL'
  , TRAIT_PLACEMENT_CTT_PLANT: 'TRAIT_PLACEMENT_CTT_PLANT'
  , TRAIT_PLACEMENT_NO_HOST: 'TRAIT_PLACEMENT_NO_HOST'

  , TRAIT_ACTION_DEFENSE: 'TRAIT_ACTION_DEFENSE'
  , TRAIT_ACTION_NO_TRAIT: 'TRAIT_ACTION_NO_TRAIT'
  , TRAIT_ACTION_DISABLED: 'TRAIT_ACTION_DISABLED'
  , TRAIT_ACTION_NOT_EXISTS: 'TRAIT_ACTION_NOT_EXISTS'
  , TRAIT_ACTION_NO_TARGETS: 'TRAIT_ACTION_NO_TARGETS'
  , TRAIT_ACTION_NO_VALUE: 'TRAIT_ACTION_NO_VALUE'
  , TRAIT_ACTION_SPECIFIC: 'TRAIT_ACTION_SPECIFIC'
  , TRAIT_ACTION_ONLY_LINK_SOURCE: 'TRAIT_ACTION_ONLY_LINK_SOURCE'

  , TRAIT_TARGETING_HIDDEN: 'TRAIT_TARGETING_HIDDEN'
  , TRAIT_TARGETING_LINKED: 'TRAIT_TARGETING_LINKED'
  , TRAIT_TARGETING_SAME_ANIMAL: 'TRAIT_TARGETING_SAME_ANIMAL'
  , TRAIT_TARGETING_ANIMAL_NO_FOOD: 'TRAIT_TARGETING_ANIMAL_NO_FOOD'
  , TRAIT_TARGETING_ANIMAL_SATURATED: 'TRAIT_TARGETING_ANIMAL_SATURATED'
  , TRAIT_TARGETING_NO_FOOD_ON_TRAIT: 'TRAIT_TARGETING_NO_FOOD_ON_TRAIT'
  , TRAIT_TARGETING_TYPE_ANIMAL: 'TRAIT_TARGETING_TYPE_ANIMAL'

  , COUNTERATTACK_WRONG_TYPE: 'COUNTERATTACK_WRONG_TYPE'

};