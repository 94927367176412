export const TraitWaiter = 'TraitWaiter';

export const TraitCarnivorous = 'TraitCarnivorous';
export const TraitParasite = 'TraitParasite';
export const TraitFatTissue = 'TraitFatTissue';
export const TraitSwimming = 'TraitSwimming';
export const TraitRunning = 'TraitRunning';
export const TraitMimicry = 'TraitMimicry';
export const TraitScavenger = 'TraitScavenger';
export const TraitSymbiosis = 'TraitSymbiosis';
export const TraitPiracy = 'TraitPiracy';
export const TraitTailLoss = 'TraitTailLoss';
export const TraitCommunication = 'TraitCommunication';
export const TraitGrazing = 'TraitGrazing';
export const TraitPlantGrazing = 'TraitPlantGrazing';
export const TraitMassive = 'TraitMassive';
export const TraitHibernation = 'TraitHibernation';
export const TraitPoisonous = 'TraitPoisonous';
export const TraitCooperation = 'TraitCooperation';
export const TraitBurrowing = 'TraitBurrowing';
export const TraitCamouflage = 'TraitCamouflage';
export const TraitSharpVision = 'TraitSharpVision';

export const TraitShell = 'TraitShell';
export const TraitIntellect = 'TraitIntellect';
export const TraitAnglerfish = 'TraitAnglerfish';
export const TraitTrematode = 'TraitTrematode';
export const TraitMetamorphose = 'TraitMetamorphose';
export const TraitInkCloud = 'TraitInkCloud';
export const TraitViviparous = 'TraitViviparous';
export const TraitAmbush = 'TraitAmbush';
export const TraitFlight = 'TraitFlight';
export const TraitThermosynthesis = 'TraitThermosynthesis';
export const TraitPhotosynthesis = 'TraitPhotosynthesis';
export const TraitSpecialization = 'TraitSpecialization';

export const TraitAedificator = 'TraitAedificator';
export const TraitCnidocytes = 'TraitCnidocytes';
export const TraitHerding = 'TraitHerding';
export const TraitMigration = 'TraitMigration';
export const TraitNeoplasm = 'TraitNeoplasm';
export const TraitRecombination = 'TraitRecombination';
export const TraitRegeneration = 'TraitRegeneration';
export const TraitSuckerfish = 'TraitSuckerfish';

export const TraitRstrategy = 'TraitRstrategy';
export const TraitHomeothermy = 'TraitHomeothermy';
export const TraitPlantHomeothermy = 'TraitPlantHomeothermy';
export const TraitShy = 'TraitShy';

export const TraitInfected = 'TraitInfected';
export const TraitPerspicuus = 'TraitPerspicuus';
export const TraitVomitus = 'TraitVomitus';
export const TraitSkinny = 'TraitSkinny';
export const TraitAmphibious = 'TraitAmphibious';
export const TraitAggression = 'TraitAggression';
export const TraitMutation = 'TraitMutation';
export const TraitAdaptation = 'TraitAdaptation';
export const TraitVoracious = 'TraitVoracious';
export const TraitOviparous = 'TraitOviparous';
export const TraitPlantOviparous = 'TraitPlantOviparous';
export const TraitCannibalism = 'TraitCannibalism';
export const TraitParalysis = 'TraitParalysis';
export const TraitPest = 'TraitPest';
export const TraitFlea = 'TraitFlea';
export const TraitHumus = 'TraitHumus';

export const TraitSpores = 'TraitSpores';
export const TraitCystInitial = 'TraitCystInitial';
export const TraitCyst = 'TraitCyst';
export const TraitStressful = 'TraitStressful';
export const TraitMammal = 'TraitMammal';